* {
  /* font-size: 62.5%; */
}
.normal-black-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  padding: 10px 20px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  /* width: 90%; */
  padding: 10px 0px;
  width: 100%;
  /* padding: 10px 20px; */
}

.normal-black-button:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 9px;
  /* transform: scale(1.01); */
}

.error-message {
  color: red;
  font-size: 12px;
}
.error-icon {
  position: absolute;
  right: 1rem;
  color: #ff0000;
  /* font-size: 1.2rem; */
}
