.step3-container {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 200px 1fr;
}

.step3-left-container {
	padding: 20px;
}

.step3-right-container {
	display: grid;
	grid-template-columns: 200px 1fr;
	grid-template-rows: 1fr;
	outline: 1px solid lightgrey;
	position: relative;
}

/* Hide scrollbar for IE, Edge and Firefox */
/* .build-list-container::-webkit-scrollbar {
  display: none;
} */

.builds-list::-webkit-scrollbar {
	display: none;
}

.part-list::-webkit-scrollbar {
	display: none;
}

.build-list-container,
.builds-list {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.build-list-container {
	top: 0;
	display: flex;
	flex-direction: column;
	height: 70vh;
}

.builds-list,
.part-list {
	overflow: scroll;
	/* height: 70vh; */
	padding-top: 4%;
	padding-bottom: 4%;
}

.build-list-header,
.part-list-header {
	padding-left: 10px;
	text-align: left;
	margin-bottom: 10px;
	height: 35px;
	color: rgb(90, 89, 89);
	border-bottom: 1px solid #ddd;
}

.part-list-container {
	border-left: 1px solid lightgrey;
	position: relative;
	/* background-color: red; */
}

.part-list-header {
	display: flex;
	align-items: center;
	justify-content: center;
}

.builds {
	width: 100%;
	display: grid;
	grid-template-columns: 5px 2fr 1fr 5px;
	grid-template-rows: 1fr;
	align-items: center;
	justify-content: center;
	gap: 0.2em;
	padding-bottom: 5%;
}

.builds div {
	width: 100%;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.builds div:first-child {
	grid-column: 2/3;
}

.builds button {
	padding: 3px;
	width: 95%;
	height: 2.5rem;
	border: none;
	outline: 1px solid lightgrey;
	border-radius: 2%;
}

.builds div:nth-child(2) button {
	border-radius: 15%;
	grid-column: 3/4;
	background-color: rgb(46, 36, 36);
	color: white;
	padding: 5px;
}

.builds button:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 9px;
}

.builds button.arrow {
	transition: transform 0.3s ease;
}

.builds button.arrow:hover {
	transform: translateY(-3px) rotate(10deg);
}

/* Active Build Style */
.builds.active div:first-child button {
	background-color: gray;
	color: white;
}

/* PARTS LIST */
.part-list {
	overflow: scroll;
	height: 59vh;
	padding-top: 4%;
	padding-bottom: 4%;
}
.part-list-container {
	/* height: 79vh; */
	/* height: 100%; */
}

.part-list {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */

	padding: 9px;
	/* position: relative; */
	/* margin-top: 64px; */
	/* outline: 1px solid lightgrey; */
}

.part-header {
	position: relative;
	top: 0;
	display: flex;
	width: 100%;
	flex-direction: row;
	padding: 9px;
	color: rgb(90, 89, 89);
	z-index: 1;
}

.part-row {
	display: flex;
}

.part-cell {
	flex: 1;
	padding: 8px;
	background-color: white;
	border: 1px solid #ccc;
}

.view-btn {
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.3rem;
	font-weight: bold;
	outline: 1px solid grey;
	border-radius: 4px;
	padding: 3px 7px;
}

.view-btn:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 9px;
}

.build-time-container {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	gap: 5px;
	font-weight: bold;
	font-size: 1.1rem;
	outline: 1px solid lightgray;
	padding: 1rem;
	background-color: rgb(90, 89, 89, 0.1);
}

.build-time-container span {
	color: green;
}

.threejs-main-container {
	z-index: 1000;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.threejs-model-container {
	z-index: 1000;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}
