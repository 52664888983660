.folder-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
}
.folder-container:hover {
  background-color: #e4f4f4;
}
.folder-name {
  margin-left: 8px;
}
.dir-btn {
  color: "lightblue";
}
.folder-toggle {
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .folder-content {
  margin-top: 10px;
  margin-left: 30px;
} */
.folder-content {
  margin-left: 30px;
  padding-top: 5px;
  overflow: hidden;
  height: 100%;
  transition: max-height 0.3s ease;
}
.folder-content.open {
  max-height: 25rem;
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.folder-content.open::-webkit-scrollbar {
  display: none;
}

.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.file-item span {
  margin-left: 5px;
}
