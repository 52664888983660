.loadingDots {
  display: flex;
  gap: 4px;
  /* justify-content: space-between; */
}

.loadingDot {
  width: 3px;
  height: 3px;
  background-color: #fff; /* Dot color */
  border-radius: 50%;
  animation: loadingAnimation 1.5s infinite ease-in-out;
}

.loadingDot:nth-child(2) {
  animation-delay: 0.5s; /* Adjust the delay for the second dot */
}

.loadingDot:nth-child(3) {
  animation-delay: 0.8s; /* Adjust the delay for the third dot */
}

@keyframes loadingAnimation {
  0%,
  20% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
