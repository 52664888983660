.step2-container {
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr;
}
.step2-left-container {
  padding: 20px;
}
.step2-right-container {
  /* background-color: blue; */
}
.step2-card-container {
  width: 80%;
  height: 74.8vh;
  padding: 30px;
  margin: auto;
  outline: 1px solid lightgrey;
  overflow-x: hidden;
  overflow-y: scroll;
}
.step2-card-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.step2-card-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* New css for step 2 ----------------------------------------------------*/
/* .step2-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  height: 79vh;
} */

.step2-right-container {
  flex-grow: 1;
  /* margin-left: 20px; */
  overflow: auto;
}

.step2-card-container {
  background-color: #fff;
  outline: 1px solid #ddd;

  /* display: flex; */
  /* justify-content: space-between; */
}

.step2-table {
  width: 100%;
  border-collapse: collapse;
}

.step2-table th,
.step2-table td {
  padding: 10px;
  text-align: left;
  height: 100%;
  /* display: flex; */
  border-bottom: 1px solid #ddd;
}

.step2-table th {
  font-weight: bold;
  /* display: flex; */
  /* align-items: center; */
}
.step2-table tr {
  height: 10px;
}
.step2-table select {
  width: 100px;
  padding: 5px;
  font-size: 14px;
}

.step2-table td:first-child {
  font-weight: bold;
}

.step2-table td:last-child {
  text-align: center;
}
