.error-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; /* Ensure the popup appears on top of other content */
}

.error-message-popup {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Add a shadow for a raised effect */
	text-align: center;
}

.error-message-popup h2 {
	color: red;
	margin-bottom: 10px;
}

.error-message-popup p {
	font-size: 16px;
	color: #333;
}

.close-button {
	background-color: #333;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 5px 10px;
	cursor: pointer;
}

.close-button:hover {
	background-color: #555;
}
