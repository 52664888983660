.Completed-jobs-list-container {
}
.jobs-list-item {
  padding: 10px;
  color: grey;
  margin-top: 7px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;

  text-transform: capitalize;
  justify-content: space-between;
  align-items: center;
}
.jobs-list-item:hover {
  background-color: #eeeeee;

  transition: ease-in-out 0.4s;
}
.list-icon-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
}
.dropdown-list {
  border: 1px solid black;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
}
.delete-list:hover {
  color: rgb(207, 31, 31);
}

/* dropdown css on list item */
