.loader {
	display: grid;
	align-items: center;
	justify-content: center;
}

.loader::before {
	content: "";
	display: block;
	width: 2rem;
	height: 2rem;
	border: 4px solid #eee;
	border-top-color: #0070f3;
	border-radius: 50%;
	animation: loader 1.2s linear infinite;
}

@keyframes loader {
	to {
		transform: rotate(360deg);
	}
}
