/* Tab list container */
.tab-list {
	display: flex;
	gap: 10px;
	margin-bottom: 10px;
	/* border-bottom: 1px solid grey; */
	justify-content: space-between;
}

/* Tab button styles */
.tab-list button {
	border: none;
	border-bottom: 2px solid transparent;
	background: none;
	font-size: 16px;
	color: #555;
	padding: 10px 15px;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}

/* Active tab button styles */
.tab-list button.active {
	border-bottom-color: black;
	color: black;
}

/* Tab button hover styles */
.tab-list button:hover {
	color: grey;
	border-bottom-color: grey;
}
/* SEARCH BAR CSS */
.searchBar {
	display: flex;
	width: 50%;
	align-items: center;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 5px;
	justify-content: space-between;
	margin-left: 10px;
	margin-bottom: 10px;
}

.searchBar input {
	border: none;
	outline: none;
	padding: 5px;
	width: 100%;
}

.searchBar input::placeholder {
	color: #999;
}
