.mhr-container {
	border: 1px solid #dcdcdc;
	border-radius: 10px;
	padding: 20px;
	width: 96%;
	color: #454545;
	background-color: #efefef;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.input-container {
	margin-top: 25px;
	margin-left: 20px;
}

.input-group {
	padding: 15px;
	margin-left: 70px;
	/* margin-top: -7px; */
	font-size: 16px;
}

.fixedCost-container {
	margin-left: 10px;
	display: grid;
	grid-template-columns: 1fr 8fr;
}

.fixedCost-heading {
	font-weight: 600;
}

.fixedcosts {
	margin-top: -6px;
	margin-bottom: 15px;
}

.operatingCost-container {
	margin-top: 30px;
	margin-left: 10px;
	display: grid;
	grid-template-columns: 1fr 6fr;
}

.operatingcost-heading {
	font-weight: 700;
	margin-top: 10px;
}

.operating-costs {
	margin-bottom: 15px;
}

.currency-symbol {
	height: 40px;
	width: 35px;
	padding: 2px;
	color: green;
	margin-top: 5px;
	font-weight: 500;
	border: 1px solid darkgray;
	border-radius: 0px 7px 7px 0px;
	background-color: #eef6f0;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.unit {
	height: 40px;
	width: 35px;
	padding: 2px;
	color: black;
	margin-top: 5px;
	font-weight: 500;
	border: 1px solid darkgray;
	border-radius: 0px 7px 7px 0px;
	background-color: #efefef;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.input-group label {
	margin-left: 10px;
}

.input-group input {
	width: 56%;
	height: 40px;
	font-size: 16px;
	text-align: center;
	margin-top: 5px;
	border: 1px solid darkgray;
	border-top-left-radius: 7px;
	border-bottom-left-radius: 7px;
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.calculate {
	height: 40px;
	width: 150px;
	font-weight: 900;
	border-radius: 7px;
	margin-left: 36%;
	margin-top: 30px;
	margin-bottom: 30px;
	background-color: #454545;
	color: whitesmoke;
}

.dialog-overlay {
	top: 0px;
	left: 0px;
	backdrop-filter: blur(2px);
}

.dialog-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	backdrop-filter: blur(5px);
}

.dialog-box {
	position: relative;
	background-color: white;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 150px;
	width: 300px;
	border: 1.5px solid #888;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close-button {
	position: absolute;
	top: 2px;
	right: 0;
	background-color: transparent;
	color: #454545;
}

.close-button:hover {
	background-color: transparent;
}

.content {
	height: 100px;
	width: 250px;
	border: 2px solid darkgray;
	border-radius: 7px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #efefef;
	font-size: medium;
}

/* Scroll bar */
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 7px;
}

::-webkit-scrollbar-thumb:active {
	background: #666;
}
