:root {
	/* --header-height: 3rem;
  --nav-width: 68px; */
	--header-height: 3.4rem;
	--nav-width: 65px;

	--first-color: #454545;
	--first-color-alt: #ff6000;
	--white-color: white;
	--orange-dark: #ff5c35;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
}

main {
	position: relative;
	margin: var(--header-height) 0 0 0;
	padding: 0 1rem;
	font-size: 1rem;
	font-family: "Nunito Sans", sans-serif;
	transition: 0.5s;
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	height: var(--header-height);
	width: 100%;
	display: grid;
	grid-template-columns: 100px 1fr 100px 200px;
	justify-content: space-between;
	align-items: center;
	/* background-color: black; */
	background-image: linear-gradient(90deg, black, #004225);
	/* border-bottom: 2px solid lightgrey; */
	padding: 0 1rem;
	transition: 0.5s;
}

.header-toggle {
	font-size: 1.25rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: left;
	color: var(--first-color);
}

.sidebar {
	position: fixed;
	top: 0;
	left: -30%;
	height: 100vh;
	width: var(--nav-width);
	/* background-color: var(--first-color); */
	background-image: linear-gradient(180deg, black, #004225);
	padding-top: 1rem;
	transition: 0.5s;
}

.nav {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	height: 100%;
}

.nav-logo,
.nav-link {
	display: grid;
	grid-template-columns: max-content max-content;
	align-items: center;
	column-gap: 2rem;
	padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav-logo {
	margin-bottom: 4rem;
}

.nav-logo-icon {
	font-size: 1.25rem;
	/* color: var(--white-color); */
	cursor: pointer;
}

.nav-logo-name {
	font-weight: 1200;
	color: var(--white-color);
}

.nav-link {
	position: relative;
	color: var(--first-color-alt);
	transition: 0.5s;
	margin-bottom: 1.5rem;
	color: rgb(255, 255, 255, 0.7);
}

.nav-link:hover {
	/* color: var(--white-color); */
	/* background-color: var(--orange-dark); */
	background-color: rgb(18, 96, 58);

	color: rgb(255, 255, 255, 1);
}

.side-active {
	color: var(--white-color);
	background-color: rgba(0, 0, 0, 0.1);
}

.side-active::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 38px;
	width: 3px;
	background-color: var(--white-color);
	transition: 0.5s;
}

.show {
	left: 0;
}

.space-toggle {
	padding-left: calc(var(--nav-width) + 1rem);
}

div > img {
	width: 100%;
	height: 100%;
	display: block;
}
.fade-in-image {
	animation: fadeIn 2s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.close_nav {
	width: auto;
	height: 16px;
	margin-left: -15px;
	/* transition: 0.5s; */
}
.open_nav {
	width: auto;
	height: 16px;
	margin-left: -6px;
	/* transition: 0.5s; */
	display: flex;
	align-items: center;
	justify-content: center;
}
.open_nav img {
	width: auto;
	height: 30px;
	margin-left: -25px;
	margin-top: -1px;
	/* transition: 0.5s; */
}

/* Css other that basic nav bar ------------------------------------ */
.bell {
	grid-column: 3/4;
	display: flex;
	justify-content: center;
	align-items: center;
}
.header-profile-container {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	/* outline: 1px solid grey; */
	padding: 5px 10px 5px 10px;
	border-radius: 8px;
	color: grey;
	opacity: 0.5;
}
.header-profile-container:hover {
	/* outline: 1px solid white; */
	color: white;
	opacity: 1;
}
.header-profile-container div:first-child {
	display: flex;
	align-items: center;
	justify-content: center;
	color: grey;
}
.header-profile-container div:nth-child(2) {
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}
.profile {
	/* border-radius: 50%;
	grid-column: 3/5;
	display: flex;
	align-items: center;
	margin: auto;
	height: 40px;
	width: 40px;
	position: relative;
	box-shadow: 0 18px 32px -9px #27252538;
	cursor: pointer; */
	/* cursor: pointer;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	grid-column: 5/6;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: lightgreen; */
	grid-column: 5/6;
}
.userProfileContainer {
	cursor: pointer;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: lightgreen;
}
.profile svg {
	border-radius: 50%;
}
/* .profile svg:hover {
  color: black !important;
  background-color: white !important;
} */

.profile-dropdown {
	position: absolute;
	top: 100%;
	right: 1rem;
	z-index: 1;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	/* padding: 10px; */
	margin-top: 2px;
	display: none;
	animation: fadeIn 0.3s ease-in;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	animation-fill-mode: forwards;
	transform-origin: top;
}

.profile-dropdown.open {
	display: block;
}

.dropdown-menu {
	width: 100%;
	align-items: center;
	list-style: none;
	width: 300px;
	padding: 0;
	margin: 0;
}

.dropdownItem {
	padding: 10px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	/* color: #343537; */
	color: gray;
	transition: 500ms ease;
}

.dropdownItem:hover {
	background-color: #f1f1f1;
	color: black;
	fill: black;
}
.listItem {
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 1rem;
}

/* .dropdown-menu li {
	padding: 10px;
	transition: background-color 0.3s ease-in;
}

.dropdown-menu li:hover {
	background-color: #f1f1f1;
}

.dropdown-menu li a {
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 1rem;
	color: #333;
	font-weight: 600;

	text-decoration: none;
}

.dropdown-menu li a:hover {
	color: #555;
} */

@keyframes fadeIn {
	0% {
		opacity: 0;
		/* transform: translateY(-10px); */
	}
	100% {
		opacity: 1;
		/* transform: translateY(0); */
	}
}
@media screen and (min-width: 768px) {
	main {
		margin: calc(var(--header-height) + 1rem) 0 0 0;
		padding: 0 1rem 0 calc(var(--nav-width) + 2rem);
	}

	.header {
		/* height: calc(var(--header-height) + 1rem); */
		padding-left: calc(var(--nav-width) + 2rem);
	}

	.sidebar {
		left: 0;
		padding: 1rem 0 0 0;
	}

	.show {
		width: calc(var(--header-height) + 156px);
	}

	.space-toggle {
		padding-left: calc(var(--nav-width) + 188px);
	}
	.responsive-toggle {
		display: none;
	}
}
@media screen and (max-width: 768px) {
	.show {
		width: 100%;
		z-index: 1;
	}
	.responsive-toggle {
	}
	.profile-username p {
		display: none;
		background-color: red;
	}
	.header-profile-container {
		/* display: none; */
		outline: none;
		border: none;
	}
}
