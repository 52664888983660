.settings-container {
	border: 1px solid #dcdcdc;
	border-radius: 10px;
	padding: 30px;
	margin-left: 10px;
	margin-top: 80px;
	margin-right: 10px;
	margin-bottom: 10px;
	/* box-shadow: 10px 5px 5px gray; */
	box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
	display: flex;
	flex-direction: column;
	font-family: sans-serif;
}
.settings-container h1 {
	margin-top: 10px;
}
.top-btn-container {
	margin-bottom: 20px;
}

h1 {
	font-size: 24px;
}

.currency-container {
	display: flex;
	margin: 10px 15px 10px 0px;
}

.add-machine-button {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 7px;
	padding: 8px 20px;
	background-color: #454545;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.tab-navigation {
	width: 96%;
	display: flex;
	margin-bottom: 20px;
	background-color: #f9f9f9;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.tab {
	height: 20px;
	width: 150px;
	margin: 10px;
	text-align: center;
	cursor: pointer;
}

.tab:hover {
	color: gray;
}

.tab.active {
	color: #000;
	position: relative;
}

.tab.active::after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: #454545;
}

.machine-table {
	width: 95%;
	border-collapse: collapse;
}

.machine-table th,
.machine-table td {
	padding: 10px;
	border: 1px solid #ccc;
	text-align: center;
}

.machine-table th {
	background-color: #454545;
	color: #fff;
	font-weight: bold;
}

.machine-table td {
	font-size: 14px;
}

.machine-table td:first-child {
	font-weight: bold;
}

.machine-table tr:nth-child(even) {
	background-color: #efefef;
}

.machine-table tr:nth-child(odd) {
	background-color: #ffffff;
}
/* Modal css for settings */
/* Existing styles */

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
}

.settings-modal-content {
	background-color: #fff;
	padding: 20px;
	width: 40%;
	overflow-y: scroll;
	border-radius: 4px;
	position: relative;
}
.settings-modal-content::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.settings-modal-content {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.delete-modal-content {
	background-color: #fff;
	padding: 1rem;
	width: 20%;
	border-radius: 4px;
	position: relative;
	display: flex;
	flex-direction: column;

	z-index: 1;
}
.delete-modal-content h4 {
	padding: 1rem;
}
.delete-modal-btn {
	padding: 1rem;
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.delete-modal-btn button {
	padding: 8px 16px;
	background-color: black;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.modal h2 {
	margin-top: 0;
}

.modal form label {
	display: block;
	margin-bottom: 10px;
}

.modal form input,
select {
	width: 100%;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.settings-form-btn {
	display: flex;
	align-items: center;
	gap: 1rem;
	justify-content: left;
}
.settings-form-btn button:hover,
.top-btn-container button:hover {
	box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 9px;
}
.modal form button {
	padding: 8px 16px;
	background-color: black;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}
/* errors  */
/* Add this CSS to your settings.css file or style tag */

/* Add this class to highlight the input fields with errors */
.input-error {
	border: 1px solid red !important;
}

td.actions {
	display: flex;
	justify-content: space-evenly;
}

/* edit btn */
.edit-button {
	background-color: lightgrey;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.3rem;
	color: black;
	border: none;
	outline: 1px solid rgb(151, 151, 151);
	padding: 5px 10px;
	cursor: pointer;
}

.edit-button:hover {
	background-color: gray;
	color: #ffffff;
}

/* delete btn */
.delete-button {
	background-color: lightgrey;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.3rem;
	color: black;
	border: none;
	outline: 1px solid rgb(151, 151, 151);
	padding: 5px 10px;
	cursor: pointer;
}

.delete-button:hover {
	background-color: #cc0000;
	color: #ffffff;
}
.settings-cross-btn {
	position: absolute;
	right: 1rem;
	top: 1rem;
}
.settings-cross-btn:hover {
	transform: scale(1.08);
}
