.home-main-container {
  /* background-color: lightgrey; */
}

.job-btn-container h1 {
  /* background-color: red; */
  margin-left: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.create-job-btn {
  margin-left: 20px;
  /* background-color: red; */
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  justify-content: center;
  align-items: center;
  font-weight: 100;
  font-size: smaller;
  padding: 8px 20px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.create-job-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 9px;
}

.tabs-list {
  /* min-height: 70vh; */
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 20px;
  padding: 20px;
  margin: 20px;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

/* Drawer css -------------------------------- */
.stepper-parent-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  filter: blur(20px);
  position: fixed;
  top: 0;
  left: 0;
}

.drawer-container {
  position: absolute;
  top: 0;
  right: 0;
  height: calc(97vh - 2.4rem);
}

.drawer {
  position: fixed;
  top: 0;
  right: -93.1vw;
  width: 93vw;
  height: 100%;
  background-color: #fff;
  transition: transform 0.3s ease-out;
  display: grid;
  grid-template-rows: 40px 1fr 100px;
  /* background-color: blue; */
}

.drawer.open {
  transform: translateX(-100%); /* Slide into view */
  /* right: 0; */
}

.drawer.close {
  /* display: none; */
}

.home-main-container {
  /* overflow: hidden; */
  height: 87vh;
}

.drawer-content {
  /* padding: 20px; */
  /* background-color: red; */
  z-index: 10000;
}

.open-drawer-btn {
  position: absolute;
  top: 20px;
  left: 20px;
}

.drawer-navbar {
  width: 100%;
  height: 40px;
  border: none;
  border-bottom: 1px solid lightgrey;
  outline: none;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.drawer-navbar > svg {
  cursor: pointer;
}

.close-drawer-btn {
  width: auto;
  height: 33px;
  color: black;
  padding: 6px;
}

.close-drawer-btn:hover {
  transform: scale(1.08);
}

/* pop up on close drawer css */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.popup-content {
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popup-title {
  font-size: 24px;
  font-weight: bold;
}

.popup-close-button {
  border: none;
  background: none;
  color: #ccc;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease-in-out;
}

.popup-close-button:hover {
  color: #555;
}

.popup-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.popup-button {
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  outline: 1px solid lightgrey;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.popup-button.primary {
  background-color: black;
  color: #ffffff;
}

.popup-button.secondary {
  background-color: #f8f9fa;
  color: #000000;
}

.popup-button.secondary:hover {
  background-color: #dcdcdc;
  color: #000000;
}

.popup-button:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .drawer {
    right: auto;
    width: 100vw;
  }

  .drawer.open {
    transform: translateX(-100%); /* Slide into view */
  }
}
