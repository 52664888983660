.stepper-container {
  display: grid;
  grid-template-columns: 1fr 200px;
  border-top: 1px solid lightgrey;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 30px; */
  width: 100%;
  padding: 0 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px;
  height: 100%;
}

.step-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.step-label {
  font-size: 12px;
  text-align: center;
  color: #777;
  transition: all 0.2s ease-in-out;
}

.step.active .step-number {
  background-color: black;
}

.step.active .step-label {
  color: black;
  font-weight: bold;
}

.step:not(.active) .step-number {
  transform: scale(0.8);
  opacity: 0.5;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  /* margin-top: 30px; */
  /* margin-bottom: 30px; */
}

.stepper-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: red; */
}
.step-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.step-btn button {
  padding: 10px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  width: 90%;
  padding: 10px 0px;
}
.step-btn button:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 6px 9px;
  /* transform: scale(1.01); */
}
.next-inactive {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
}
