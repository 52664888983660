.horizontal-wave-loader-container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: fit-content;
}

.horizontal-wave-loader {
	width: 4px;
	padding-left: 3px;
	height: 8px;
	margin: 0 2px;
	background-color: #3498db;
	animation: wave 1s ease-in-out infinite;
}

.horizontal-wave-loader:nth-child(2) {
	animation-delay: -0.5s;
}

.horizontal-wave-loader:last-child {
	animation-delay: -1s;
}

@keyframes wave {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-15px);
	}
	100% {
		transform: translateY(0);
	}
}
