.try-again-button-container {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
button.try-again-button {
  background-color: #484f56;
  color: #fff;
  border: none;
  padding: 7px 20px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  cursor: pointer;
  position: relative;
}
div.placeholder {
  text-align: center;
  margin: 7px auto;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #999; */
  color: red;
}
button.try-again-button:hover {
  background-color: #999;
}

button.try-again-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(81, 81, 81, 0.16);
}

button.try-again-button:active {
  background-color: #333333;
}
