.step1-container {
  width: 100%;
  height: 100%;
  height: 69vh;
  display: grid;
  grid-template-columns: 200px 1fr;
}

.step1-left-container {
  padding: 20px;
}

.step1-right-container {
  /* background-color: blue; */
}

.step1-card-container {
  width: 80%;
  height: 74.8vh;
  padding: 30px;
  margin: auto;
  outline: 1px solid lightgrey;
  overflow-x: hidden;
  overflow-y: scroll;
}

.step1-card-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.step1-card-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* card css for shake effect from other resourse */

.job-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.field {
  position: relative;
  width: 100%;
}

.input-area {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  /* padding: 1rem; */
}

.input-area input {
  height: 40px;
  padding: 7px;
}

.input-area svg {
  margin-right: 10px;
}

.input-area:hover {
  outline: 1px solid black;
}

.input-area input[type="text"] {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1rem;
  width: 100%;
}

.input-area input[type="text"]::placeholder {
  color: #b3b3b3;
}

/* css for dropdown from external source */
.select-btn {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 36px;
  font-size: 0.8rem;
  color: #444;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-left: 10px;
  overflow: hidden;
}

.select-btn select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.select-btn label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.select-btn label::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-left: 8px;
  border-top: 6px solid #444;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.select-btn:hover {
  border-color: #aaa;
}

.select-btn:focus-within {
  /* border-color: #2196f3; */
  /* border-color: black; */
}

.select-btn:focus-within label {
  color: #2196f3;
}

.select-btn option {
  font-size: 14px;
  color: #444;
  background-color: #fff;
}

.select-btn {
  width: 100%;
}

.select-btn:focus-visible {
  outline: none;
}

/* my css */

.addButton {
  padding: 19px;
  height: 25px;
  border: 1px solid lightgrey;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  width: fit-content;
  cursor: pointer;
}
.addButton:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.addButton > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* CSS transition test
 */
.fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

@media (max-width: 990px) {
  /* .step1-card-container {
    width: 100%;
  } */
}
