.Completed-jobs-list-container {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 50vh;
	flex: 1;
	position: relative;
}

.jobs-list-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	outline: 1px solid #ccc;
	text-transform: capitalize;
	/* margin-bottom: 10px; */
}

.list-icon-container {
	display: flex;
	width: fit-content;
	gap: 2rem;
	align-items: center;
}

.delete-list {
	margin-right: 10px;
	cursor: pointer;
}

.dropdown {
	position: relative;
	display: inline-block;
}

.normal-black-button {
	background-color: #000;
	color: #fff;
	border: none;
	padding: 5px 20px;
	cursor: pointer;
}

.pagination {
	display: flex;
	/* position: absolute; */
	bottom: 0;
	justify-content: center;
	margin-top: auto; /* Pushes the pagination to the bottom */
}

.pagination-button {
	background-color: #eee;
	color: #000;
	border: none;
	padding: 8px 12px;
	margin: 0 5px;
	cursor: pointer;
}

.pagination-button.active {
	background-color: #000;
	color: #fff;
}
