.canvas-bottom {
  position: absolute;
  bottom: 10px;
  left: 10px;
  /* background-color: red; */
  color: white;
  opacity: 0.8;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 97%;
  justify-content: space-between;
}
