.total-build-cost,
.total-build-time {
	/* position: absolute; */
	width: 100%;
	/* height: 50px; */
	/* bottom: 50px; */
	background-color: lightyellow;
	left: 0;
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: center;
	outline: 1px solid lightgrey;
}

.total-build-time {
	width: unset;
	height: unset;
	margin: 5px;
	padding: 10px;
	font-size: 12px;
}

.total-build-cost,
.total-job-cost div {
	padding: 8px;
}

.step4-total-cst {
	/* outline: 0.1px solid darkgreen; */
	border-left: 1px solid lightgrey;
	color: rgb(255, 98, 0);
	padding-left: 2px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* JOB COST */
.total-job-cost {
	width: 100%;
	/* height: 50px; */
	background-color: #eef6f0;
	color: #31a246;
	display: grid;
	grid-template-columns: 2fr 1fr;
	align-items: center;
	outline: 1px solid lightgrey;
	font-size: 14px;
}

.step4-total-job-cst {
	/* outline: 0.1px solid darkgreen; */
	border-left: 1px solid lightgrey;
	color: rgb(255, 0, 0);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.step4-right-container {
	display: flex;
	grid-template-columns: 200px 1fr;
	grid-template-rows: 1fr;
	/* outline: 1px solid lightgrey; */
	/* position: relative; */
	flex-direction: column;
}

.step4-builds-container {
	height: 100%;
	display: grid;
	grid-template-columns: 200px 1fr;
	grid-template-rows: 1fr;
	outline: 1px solid lightgrey;
	position: relative;
}

.part-list.step4 {
	height: 58vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* padding: 9px; */
}

/* .builds-list,
.part-list.step4 {
  overflow: scroll;
} */

/* Hide scrollbar for Chrome, Safari and Opera */
.step4-list-scroll::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.step4-list-scroll {
	-ms-overflow-style: none; /* IE and Edge */
	overflow: auto;
	scrollbar-width: none; /* Firefox */
	padding: 10px;
}

.build-list-container.step4 {
	height: auto;
}

.canvas-main-container {
	z-index: 1000;
}
