.step1-card {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  position: relative;
  margin-top: 20px;
  transition: 20px;
}

.delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.delete-btn:hover {
  color: red;
}

.cardNumber {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: lightblue;
  border-radius: 50%;
  z-index: -10;
  font-size: small;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fade {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.green {
  color: green;
}

.part-select-div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.input-field {
  width: 100%;
  height: 36px;
  font-size: 14px;
  color: #444;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  padding-left: 10px;
}

.input-field:focus-visible {
  outline: none;
}

/* Progress bar css code */

/* .progress-bar {
 *
 *
 *
 *
 *
 *
 *
  background-color: #00d924;







  height: 13px;







  width: 100px;







  margin-bottom: 10px;







  position: relative;



  display: flex;



  align-items: center;



  justify-content: center;



}

.progress-bar > div {
  position: absolute;
  top: 0;
  bottom: 0;
  font-size: 10px;
  color: white;
  font-weight: 900;
}



.progress-bar::before {
  content: "";
  background-color: #00d924;
  display: block;
  height: 100%;
  transition: width 0.2s ease-in-out;
}



.progress-bar.complete::before {
  background-color: #2ecc40;
}


.progress-bar span {
  color: #333;
  font-weight: bold;
  left: 50%;
  position: absolute;

  top: 50%;

  transform: translate(-50%, -50%);

} */

/* css from other resourse */
.progress-bar {
  /* background-color: #222; */
  background-color: #00d924;

  border-radius: 20px;
  height: 20px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.progress-bar::before {
  content: "";
  background: linear-gradient(to right, #00d924, #2ecc40);
  display: block;
  height: 100%;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 1s ease-in-out;
}

.progress-bar > div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.progress-bar.complete::before {
  background-color: #2ecc40;
}

/* Hover effect */
.progress-bar:hover::before {
  width: 100%;
  background: linear-gradient(to right, #00d924, #2ecc40, #00d924);
}

/* Animation effect */
@keyframes progress-bar-animation {
  from {
    width: 0;
  }
}

.progress-bar.animated::before {
  animation: progress-bar-animation 2s ease-in-out;
}

/* Striped effect */
.progress-bar.striped::before {
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2) 10px,
    transparent 10px,
    transparent 20px
  );
}

/* css for choose file button within the card */
.progressBar {
  height: 8px;
  border-radius: 5px;
  background-color: #eaeaea;
  width: 100%;
}

.progress {
  outline: none;
  border: none;
  height: 100%;
  width: 2%;
  overflow: hidden;
  background-color: rgb(123, 188, 26);
  border-radius: 5px;
}
